/* globals Config:readonly require */

import React from 'react';
import ReactDOM from 'react-dom';
import GraphiQL from 'graphiql';

// Copy all static images under ../images to the output folder
// Reference them with the `imagePath` JavaScript helper below.
const images = require.context('../images', true, /\.(png|svg|jpg|gif)$/);
const imagePath = name => images(name, true); // eslint-disable-line no-unused-vars

const fetcher = async (graphQLParams, headers) => {
  const data = await fetch(Config.url, {
    method: 'POST',
    headers: { ...Config.headers, ...headers },
    body: JSON.stringify(graphQLParams),
    credentials: 'same-origin',
  });

  return data.json().catch(() => data.text());
};

ReactDOM.render(
  React.createElement(
    GraphiQL,
    { fetcher },
    React.createElement(GraphiQL.Logo, {}, 'Vaolo API Console'),
  ),
  document.getElementById('graphiql'),
);
